import { initializeLogger } from '@seamless/logger';
import { SeamlessCoreStore } from '@seamless/store';
import {
  AllowedConnections,
  OneContextConnectionObject,
  SetupContext,
  SubscriptionOptions,
  ConnectionSubscriptions,
  ExistingConnectionsType,
  EnvironmentVariables,
  PageEnvironmentVariables,
} from './types';
import {
  PageEnvironmentConnection,
  PageEnvironmentConnectionState,
  staticPageConnectionName,
} from './connections/static/page-connection-static';
import { establishConnectionAndSubscribe, getDataDogLevel } from './utils';
import { version } from '../package.json' with { type: 'json' };
import { contextTypes, ContextTypeEnum, moduleName, TENANT } from './constants';
import {
  mbScoreConnectionName,
  AffinityType,
  MBScoreState,
  MBScoreConnectionDispatchers,
  MBScoreConnection,
} from './connections/store/mbscore-connection';
import {
  DealerContextConnection,
  dealerContextConnectionName,
  DealerContextState,
} from './connections/store/dealer-context-connection';
import {
  BrandContextConnection,
  brandContextConnectionName,
  BrandContextState,
  SubBrand,
} from './connections/store/brand-context-connection';
import {
  UserVehicleConnection,
  userVehicleConnectionName,
  UserVehicleState,
} from './connections/store/user-vehicle-context';
import {
  CampaignContextConnection,
  CampaignContextState,
  campaignContextConnectionName,
} from './connections/store/campaign-context-connection';
import {
  SalesExpertConnection,
  SalesExpertState,
  SalesExpertConnectionDispatchers,
  salesExpertConnectionName,
  SalesExpertConnectionAddEventPayload,
} from './connections/store/sales-expert-connection';
import { ConsentContextConnection, consentContextConnectionName } from './connections/store/consent-context-connection';
import { UserCentricsConsentState } from './connections/store/consent-context-connection/types';
import { ProfileConnection, PROFILE_CONNECTION_NAME } from './connections/store/profile-connection';
import { ProfileState, ProfileConnectionDispatchers } from './connections/store/profile-connection';

const logger = initializeLogger(moduleName, { tenant: TENANT, level: getDataDogLevel() });

const OneContextConnections = new Map<string, OneContextConnectionObject>();

const OneContextSubscriptions = new Map<AllowedConnections, ConnectionSubscriptions[]>();

const ExistingConnections = new Map<AllowedConnections, ExistingConnectionsType>([
  [
    'PageConnection',
    {
      tenant: 'mbmxp',
      connection: PageEnvironmentConnection,
    },
  ],
  [
    'BrandContextConnection',
    {
      tenant: 'seamless',
      connection: BrandContextConnection,
    },
  ],
  [
    'MBScoreConnection',
    {
      tenant: 'cuan',
      connection: MBScoreConnection,
    },
  ],
  [
    'DealerContextConnection',
    {
      tenant: 'seamless',
      connection: DealerContextConnection,
    },
  ],
  [
    'UserVehicleConnection',
    {
      tenant: 'mmv',
      connection: UserVehicleConnection,
    },
  ],
  [
    'CampaignContextConnection',
    {
      tenant: 'seamless',
      connection: CampaignContextConnection,
    },
  ],
  [
    'SalesExpertConnection',
    {
      tenant: 'sem',
      connection: SalesExpertConnection,
    },
  ],
  [
    'ConsentContextConnection',
    {
      tenant: 'seamless',
      connection: ConsentContextConnection,
    },
  ],
  [
    'ProfileConnection',
    {
      tenant: 'iam',
      connection: ProfileConnection,
    },
  ],
]);

/**
 * Mapping the OneContext connection name to Seamless Store Connection Names.
 */
const MappedConnections = new Map<AllowedConnections, string>([
  ['PageConnection', staticPageConnectionName],
  ['BrandContextConnection', brandContextConnectionName],
  ['MBScoreConnection', mbScoreConnectionName],
  ['DealerContextConnection', dealerContextConnectionName],
  ['UserVehicleConnection', userVehicleConnectionName],
  ['CampaignContextConnection', campaignContextConnectionName],
  ['SalesExpertConnection', salesExpertConnectionName],
  ['ConsentContextConnection', consentContextConnectionName],
  ['ProfileConnection', PROFILE_CONNECTION_NAME],
]);

// Initializing a new Seamless Store Instance. Not using Window scope since everything will be under the OneContext Scope
const store = new SeamlessCoreStore();

/**
 * Setup the existing connections if they are not already setup.
 * Currently available connections are:
 * - PageConnection
 * - MBScoreConnection
 * - DealerContextConnection
 * - UserVehicleConnection
 * - CampaignContextConnection
 * - SalesExpertConnection
 * - ProfileConnection
 * @param name - The name of the connection to setup
 * @returns - void
 */
const setupConnection = async (name: AllowedConnections) => {
  const connection = OneContextConnections.get(name);

  if (!connection) {
    const existingConnection = ExistingConnections.get(name);

    if (!existingConnection) {
      logger.warn(`CONNECTION ${name} not found in OneContext`);
      return;
    }

    await setupContext({
      name: name,
      connectionMethod: existingConnection.connection,
      tenant: existingConnection.tenant,
    });
  }
};

/**
 * Adds a connection to the OneContext and to Seamless Store. Seamless Store Instance is unique, not being shared in the global scope.
 * Can add Static connections or SDS Connections.
 * @param context
 * @returns {void}
 */
const setupContext = async ({ name, connectionMethod, tenant }: SetupContext): Promise<void> => {
  if (OneContextConnections.get(name)) {
    logger.warn(`CONNECTION ${name} already exists in OneContext`);
    return;
  }

  if (!MappedConnections.get(name)) {
    logger.warn(`CONNECTION ${name} is not a valid connection`);
    return;
  }

  const connection = new connectionMethod();

  if (!connection.type) {
    logger.error(`CONNECTION type is not defined in ${connectionMethod.name}`);
    return;
  }

  if (!contextTypes.includes(connection.type)) {
    logger.error(`CONNECTION type ${connection.type} is not a valid connection type`);
    return;
  }

  if (connection.type === ContextTypeEnum.store) {
    await store.addConnection(connection);
    const oneContextObject: OneContextConnectionObject = {
      connection: connectionMethod,
      tenant,
      allowedTenants: connection.allowedTenants,
      type: connection.type,
    };

    OneContextConnections.set(name, oneContextObject);
  } else if (connection.type === ContextTypeEnum.static) {
    const context = await connection.getContext();

    OneContextConnections.set(name, {
      connection: connectionMethod,
      tenant,
      type: connection.type,
      context: context,
    });
  }
};

/**
 * Access all current OneContext connections.
 */
const connections = () => OneContextConnections.keys();

/**
 * Get subscribed tenants and components to a connection.
 * @param connectionName
 * @typedef {Object} ConnectionSubscriptions
 * @property {string} tenant - The tenant ID.
 * @property {string} componentName - The componentName.
 *
 * @returns {ConnectionSubscriptions[]} An array of connection subscriptions.
 */
const getSubscribed = (connectionName: AllowedConnections): ConnectionSubscriptions[] | void => {
  return OneContextSubscriptions.get(connectionName);
};

const unsubscribe = (
  connectionName: AllowedConnections,
  {
    tenant,
    componentName,
    callback,
  }: Pick<SubscriptionOptions, 'tenant' | 'componentName'> & Partial<Pick<SubscriptionOptions, 'callback'>>,
) => {
  const mappedConnectionName = MappedConnections.get(connectionName);
  const connection = OneContextConnections.get(connectionName);
  const oneContextSubscription = OneContextSubscriptions.get(connectionName);

  if (!mappedConnectionName || !oneContextSubscription) {
    logger.warn(`CONNECTION ${connectionName} not found in OneContext`);
    return;
  }

  if (connection?.type === 'store' && !callback) {
    logger.error(`You need to provide the callback listener to unsubscribe from a ${connection.type} connection`);
    return;
  }

  const activeSubscriptions = oneContextSubscription.filter(
    (subscription) => subscription.tenant !== tenant || subscription.componentName !== componentName,
  );

  OneContextSubscriptions.set(connectionName, activeSubscriptions);

  if (callback && connection?.type === 'store') {
    store.unsubscribe(mappedConnectionName, callback);
  }
};

/**
 * Get the dispatchers for a specific connection. The dispatcher will be returned if the tenant is allowed to mutate state.
 * @param {string} tenant - Tenant ID
 * @param {string} connectionName - Connection Name
 * @returns {T} Returns the existing dispatchers for a specific connection
 */
const getDispatchers = async <T = any>(tenant: string, connectionName: AllowedConnections): Promise<T | void> => {
  await setupConnection(connectionName);

  const connectionData = OneContextConnections.get(connectionName);
  const mappedConnectionName = MappedConnections.get(connectionName);

  if (!tenant) {
    logger.warn('Invalid tenant argument');
    return;
  }

  if (!connectionData || !mappedConnectionName) {
    logger.warn(`CONNECTION ${connectionName} not found in OneContext`);
    return;
  }

  if (connectionData.allowedTenants && connectionData.allowedTenants.includes(tenant)) {
    if (!connectionData.dispatchers) {
      const dispatchers = await store.getConnectionDispatchers(mappedConnectionName);
      connectionData.dispatchers = dispatchers;
    }

    return connectionData.dispatchers as T;
  }

  logger.warn(`Tenant ${tenant} is not able to mutate state in ${connectionName}`);
};

const subscribe = <T>(
  connectionName: AllowedConnections,
  { tenant, componentName, callback }: SubscriptionOptions<T>,
) => {
  setupConnection(connectionName);
  if (!connectionName) {
    logger.warn('Connection name was not provided');
    return;
  }

  if (!tenant) {
    logger.warn('Tenant was not provided');
    return;
  }

  if (!componentName) {
    logger.warn('Component name was not provided');
    return;
  }

  if (!callback || typeof callback !== 'function') {
    logger.warn('Missing or invalid callback.');
    return;
  }

  const mappedConnectionName = MappedConnections.get(connectionName);

  if (mappedConnectionName) {
    const currentSubscribed = OneContextSubscriptions.get(connectionName);
    if (
      currentSubscribed &&
      !currentSubscribed.find(
        (subscription: any) => subscription.tenant === tenant && subscription.componentName === componentName,
      )
    ) {
      currentSubscribed.push({
        tenant,
        componentName,
      });
      OneContextSubscriptions.set(connectionName, currentSubscribed);
    } else {
      OneContextSubscriptions.set(connectionName, [
        {
          tenant,
          componentName,
        },
      ]);
    }

    establishConnectionAndSubscribe({
      connectionsMap: OneContextConnections,
      connectionName,
      subscribedTenant: tenant,
      mappedConnectionName,
      storeInstance: store,
      stateCallback: callback,
      logger,
    });
  } else {
    logger.warn(`Connection ${connectionName} is not part of OneContext`);
  }
};

export type { PageEnvironmentConnectionState, EnvironmentVariables, PageEnvironmentVariables };
// MBScore Connection Types
export type { AffinityType, MBScoreState, MBScoreConnectionDispatchers };
// Dealer Context Connection Types
export type { DealerContextState };
// Brand Context Connection Types
export type { BrandContextState, SubBrand };
// User Vehicle Connection Types
export type { UserVehicleState };
// Campaign Context Connection Types
export type { CampaignContextState };
// Sales Expert Connection Types
export type { SalesExpertState, SalesExpertConnectionDispatchers, SalesExpertConnectionAddEventPayload };
// Consent Context Connection Types
export type { UserCentricsConsentState };
// Profilet Connection Types
export type { ProfileState, ProfileConnectionDispatchers };

// Export methods
export { setupContext, subscribe, unsubscribe, getDispatchers, getSubscribed, connections, version };
